import Vue from "vue";
import Router from "vue-router";
import store from "../../store";

import { SETTINGS_ITEMS } from "@/constants"

const MainLayout = () => import("@/layout/MainLayout");
const InfluencerSearch = () => import("@/views/InfluencerSearch");
const CampaignSearch = () => import("@/views/CampaignSearch");
const CampaignTracking = () => import("@/views/CampaignTracking");
const Inbox = () => import("@/views/Inbox");
const CampaignPipeline = () => import("@/views/CampaignPipeline");
const Settings = () => import("@/views/Settings");
const Admin = () => import("@/views/Admin");
const Home = () => import("@/views/Home");
const Workspace = () => import("@/views/Workspace");
const Help = () => import("@/views/Help");
const Controls = () => import("@/views/Controls");
const Payment = () => import("@/views/Payment");
const AuthLayout = () => import("@/layout/AuthLayout");


const hasAccess = store.getters['user/hasAccess'];


Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/app/payment",
      component: AuthLayout,
      children: [
        {
          path: "/app/payment",
          name: "payment",
          component: Payment,
          meta: {
            requiresAuth: true,
            requiresCheckout: false
          }
        },
      ]
    },
    {
      path: "/",
      redirect: "/app/home",
    },
    {
      path: "*",
      redirect: "/app/home",
    },
    {
      path: "/app",
      redirect: "/app/home",
      component: MainLayout,
      children: [
        {
          path: "/app/home",
          name: "home",
          component: Home,
          beforeEnter: (to, from, next) => {
            const shop = to.query.shop || store.state.user.authUser.shop;
            if (shop && from.path === "/app") {
              let query = { shop: shop };
              if (store.state.user.authUser.state) {
                query["state"] = store.state.user.authUser.state;
              }
              next({
                path: "/app/settings/integrations/shopify",
                query
              });
            } else {
              next();
            }
          },
          meta: {
            requiresAuth: true,
            requiresCheckout: true,
          }
        },
        {
          path: "/app/influencers",
          name: "influencers",
          component: InfluencerSearch,
          beforeEnter: (to, from, next) => {
            const shop = to.query.shop || store.state.user.authUser.shop;
            if (shop && from.path === "/app") {
              let query = { shop: shop };
              if (store.state.user.authUser.state) {
                query["state"] = store.state.user.authUser.state;
              }
              next({
                path: "/app/settings/integrations/shopify",
                query
              });
            } else {
              next();
            }
          },
          meta: {
            requiresAuth: true,
            requiresCheckout: true,
            requiresFilterbar: true
          }
        },
        {
          path: "/app/settings/:primary/:secondary",
          name: "settings_secondary",
          component: Settings,
          beforeEnter: (to, from, next) => {
            if (to.params.primary === "integrations" && hasAccess("integrations:full")) {
              next();
            } else if (to.params.primary === "integrations" && hasAccess("integrations:view")) {
              next("/app/settings/integrations");
            } else if (SETTINGS_ITEMS[to.params.primary] && hasAccess(SETTINGS_ITEMS[to.params.primary].scope)) {
              next();
            } else {
              next({
                path: "/app/settings"
              });
            }
          },
          props: true,
          meta: {
            requiresAuth: true,
            requiresCheckout: true,
            requiresFilterbar: false
          }
        },
        {
          path: "/app/settings/:primary",
          name: "settings_primary",
          component: Settings,
          beforeEnter: (to, from, next) => {
            if (SETTINGS_ITEMS[to.params.primary] && hasAccess(SETTINGS_ITEMS[to.params.primary].scope)) {
              next();
            } else {
              next({
                path: "/app/settings"
              });
            }
          },
          props: true,
          meta: {
            requiresAuth: true,
            requiresCheckout: true,
            requiresFilterbar: false
          }
        },
        {
          path: "/app/settings",
          name: "settings",
          component: Settings,
          meta: {
            requiresAuth: true,
            requiresCheckout: true,
            requiresFilterbar: false
          }
        },
        {
          path: "/app/inbox",
          name: "inbox",
          component: Inbox,
          beforeEnter: (to, from, next) => {
            if (hasAccess('emails:view') && hasAccess('campaigns:view')) {
              next();
            } else {
              next({
                path: "/app"
              });
            }
          },
          meta: {
            requiresAuth: true,
            requiresCheckout: true,
            requiresFilterbar: false
          }
        },
        {
          path: "/app/workspace",
          name: "workspace",
          component: Workspace,
          meta: {
            requiresAuth: true,
            requiresCheckout: true,
            requiresFilterbar: false
          }
        },
        {
          path: "/app/help",
          name: "help",
          component: Help,
          meta: {
            requiresAuth: true,
            requiresCheckout: true,
            requiresFilterbar: false
          }
        },
        {
          path: "/app/controls",
          name: "controls",
          component: Controls,
          meta: {
            requiresAuth: true,
            requiresCheckout: true,
            requiresFilterbar: false
          }
        },
        {
          path: "/app/campaigns",
          name: "campaigns",
          component: CampaignSearch,
          beforeEnter: (to, from, next) => {
            if (hasAccess('campaigns:view')) {
              next();
            } else {
              next({
                path: "/app"
              });
            }
          },
          meta: {
            requiresAuth: true,
            requiresCheckout: true,
            requiresFilterbar: false
          },
        },
        {
          path: "/app/campaigns/:id/tracking",
          name: "campaign_traking",
          component: CampaignTracking,
          beforeEnter: (to, from, next) => {
            if (hasAccess('campaigns:view')) {
              next();
            } else {
              next({
                // path: "/app"
              });
            }
          },
          props: true,
          meta: {
            requiresAuth: true,
            requiresCheckout: true,
            requiresFilterbar: false
          }
        },
        {
          path: "/app/campaigns/:id/pipeline",
          name: "campaign_pipeline",
          component: CampaignPipeline,
          beforeEnter: (to, from, next) => {
            if (hasAccess('campaigns:view')) {
              next();
            } else {
              next({
                path: "/app"
              });
            }
          },
          props: true,
          meta: {
            requiresAuth: true,
            requiresCheckout: true,
            requiresFilterbar: false
          }
        },
        {
          path: "/app/campaigns/:id/pipeline/leads/:lead_id",
          name: "campaign_pipeline_lead",
          component: CampaignPipeline,
          beforeEnter: (to, from, next) => {
            if (hasAccess('campaigns:view')) {
              next();
            } else {
              next({
                path: "/app"
              });
            }
          },
          props: true,
          meta: {
            requiresAuth: true,
            requiresCheckout: true,
            requiresFilterbar: false
          }
        },
        {
          path: "/app/admin",
          name: "admin",
          component: Admin,
          beforeEnter: (to, from, next) => {
            const isAdmin = store.state.user.authUser.is_admin;
            if (isAdmin) {
              next();
            } else {
              next({
                path: "/app"
              });
            }
          },
          props: true,
          meta: {
            requiresAuth: true,
            requiresCheckout: true,
            requiresFilterbar: false
          }
        }
      ]
    }
  ]
});
