import Filterbar from './FilterBar.vue';

const FilterbarStore = {
  showFilterbar: false,
  isMinimized: false,
  displayFilterbar(value) {
    this.showFilterbar = value;
    const contentElement = document.querySelector('.main-content .page-content');
    setTimeout(()=> {
      const filterWidth = document.querySelector('#filternav-collapse-main').clientWidth;
      if (contentElement) {
        contentElement.setAttribute('style', value ? `margin-left: ${filterWidth}px; margin-right: -${filterWidth}px` : 'margin-left: 0; margin-right: 0;');
      }
    });
  },
  toggleFilterbar() {
    if (!this.showFilterbar) {
      this.showFilterbar = true;
    } else {
      this.showFilterbar = false;
    }
  },
  toggleMinimize() {
    document.body.classList.toggle('filterbar-mini');
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(() => {
      clearInterval(simulateWindowResize);
    }, 1000);

    this.isMinimized = !this.isMinimized;
  }
};

const FilterbarPlugin = {
  install(Vue) {
    let app = new Vue({
      data: {
        filterbarStore: FilterbarStore
      }
    });
    Vue.prototype.$filterbar = app.filterbarStore;
    Vue.component('filter-bar', Filterbar);
  }
};

export default FilterbarPlugin;
