import Vue from "vue";
import VueGtm from '@gtm-support/vue2-gtm';
import { datadogRum } from '@datadog/browser-rum';
import App from "./App.vue";
import store from "../../store";
import router from "./router";
import filter from "../../filter.js";
import NinjaOutreachXApp from "../../plugins/NinjaOutreachXApp";

Vue.config.productionTip = false;

Vue.use(NinjaOutreachXApp);

const enableGtm = process.env.VUE_APP_ENABLE_GTM === "true";
Vue.use(VueGtm, {
  id: 'GTM-TPBTWCJ',
  enabled: enableGtm,
  debug: true,
  vueRouter: router,
  parentElement: document.head,
  loadScript: true,
});

const enableDatadog = process.env.VUE_APP_ENABLE_DATADOG === "true";
if (enableDatadog) {
  datadogRum.init({
    applicationId: '734f48a2-ff2f-4869-a5f5-b222e87fe174',
    clientToken: 'pub545a794fe1dc6dcbddf1d48917d008f3',
    site: 'datadoghq.com',
    service: 'ninjainfluence',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  });

  datadogRum.startSessionReplayRecording();
}

Vue.use(filter);


let checkout = false;
if (store.state.user.completedCheckout) {
  checkout = true;
} else {
  checkout = false;
}

let auth = false;
if (store.state.user.isLoggedIn) {
  auth = true;
} else {
  auth = false;
}

router.beforeEach(async (to, from, next) => {
  //sh.sergey - hubspot identification
  if (auth) {
    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(["identify", {
      email: store.state.user.authUser.email
    }]);
  }


  // check user checkout
  if ((to.matched.some(record => record.meta.requiresCheckout))) {

    if (!checkout) {
      const res = await store.dispatch("user/isCheckoutCompleted");

      if (!res) {
        window.location.href = "/app/payment";
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next();
  }
  // also TODO: set plan in db, receive plan on pricing page

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth) {
      window.location.href = "/app/login";
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
