import {
  MONTH_NAMES
} from "./constants";
import {
  getFormattedNormalNumber,
  getFormattedBigNumber,
  getFormattedRate,
  dateIsoToShort,
  dateIsoToLong,
  dateIsoToShortTime
} from "./utils";

import striptags from "striptags";

export default {
  install(Vue) {
    // 55,555.555 (55555.55555, 3, ".", ",")
    Vue.filter("NormalNumber", getFormattedNormalNumber);

    // $555
    Vue.filter("Currency", (n, c) => {
      let s = c === undefined ? "$" : c;
      return s + n;
    });

    // 5.5K
    Vue.filter("BigNumber", getFormattedBigNumber);

    // 0.1%
    Vue.filter("Percent", getFormattedRate);

    // May 1, 2020
    Vue.filter("FullDate", (str, show_year) => {
      if (str) {
        var date = new Date(str);
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        return (
          MONTH_NAMES[monthIndex].short +
          ". " +
          day +
          (show_year ? ", " + year : "")
        );
      }
      return str;
    });




    Vue.filter("ShortDate", (str, show_year) => {
      if (str) {
        return dateIsoToShort(str);
      }
      return str;
    });

    Vue.filter("ShortTime", (str, show_year) => {
      if (str) {
        return dateIsoToShortTime(str);
      }
      return str;
    });

    Vue.filter("ShortDateTime", (str, show_year) => {
      if (str) {
        return dateIsoToLong(str);
      }
      return str;
    });

    Vue.filter("NoDomain", url => {
      try {
        return url.substr(url.indexOf('/', 7) + 1);
      } catch {
        return url;
      }
    });

    Vue.filter("Domain", url => {
      return url.replace(/(^\w+:|^)\/\//, '');
    });


    Vue.filter("ShortIntro", str => {
      if (str.length > 100) {
        str = str.substring(0, 99);
      }
      return str;
    });

    Vue.filter("ShortInboxMsg", str => {
      if (str && str.length > 25) {
        str = str.substring(0, 25);
      }
      return str;
    });

    Vue.filter("fixedToDecimal", num => {
      let m=parseFloat(num);
      if (isNaN(m))
        return "n/a"
      return m;
    });

    Vue.filter("Truncate100", str => {
      if (str.length > 100) {
        str = str.substring(0, 99);
      }
      return str;
    });
    
    Vue.filter("StripTags", str => {
      return striptags(str);
    });

  }
};