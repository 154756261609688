<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white pb-0"
    id="filternav-main"
  >
    <a
      :class="'filter-close-button d-md-none ' + ($filterbar.showFilterbar ? 'd-block':'d-none')"
      @click="closeFilterbar"
    ></a>
    <div class="container-fluid no-p">
      <div
        :class="'navbar-collapse collapse ' + ($filterbar.showFilterbar ? 'show' : 'collapsing-out')"
        id="filternav-collapse-main"
      >
        <div class="navbar-collapse-header filter-close d-md-none">
          <!-- <navbar-toggle-button @click.native="closeFilterbar"></navbar-toggle-button> -->
        </div>
        <slot name="filters"></slot>
      </div>
    </div>
  </nav>
</template>
<script>

export default {
  name: "filterbar",
  props: {
    logo: {
      type: String,
      default: "/app/img/brand/green.png",
      description: "filterbar app logo"
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether filterbar should autoclose on mobile when clicking an item"
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    closeFilterbar() {
      this.$filterbar.displayFilterbar(false);
    },
    showFilterbar() {
      this.$filterbar.displayFilterbar(true);
    }
  },
  beforeDestroy() {
    if (this.$filterbar.showFilterbar) {
      this.$filterbar.showFilterbar = false;
    }
  }
};
</script>
<style lang="scss">
</style>
