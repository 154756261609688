import "@/assets/vendor/nucleo/css/nucleo.css";
import "@/assets/scss/main.scss";
import globalComponents from "./globalComponents";
import globalDirectives from "./globalDirectives";
import SidebarPlugin from "@/components/SidebarPlugin/index";
import FilterbarPlugin from "@/components/FilterbarPlugin/index";
import NotificationPlugin from "@/components/NotificationPlugin/index";

export default {
  install(Vue) {
    Vue.use(globalComponents);
    Vue.use(globalDirectives);
    Vue.use(SidebarPlugin);
    Vue.use(FilterbarPlugin);
    Vue.use(NotificationPlugin);
  }
};
